export const ResponseType = Object.freeze({
  'Multiple choice': 1,
  Text: 2,
  Number: 3,
  Checkbox: 4,
  'Date & Time': 5,
  Photo: 6,
  Annotation: 7,
  Signature: 8,
  Dropdown: 9,
  'No response required': 10
})
