<template>
  <v-container>
    <v-card id="page" ref="page" class="page pb-14" flat>
      <v-progress-circular v-if="loading" class="center-center" indeterminate/>
      <validation-observer v-else ref="observer" v-slot="{ invalid, handleSubmit }">
        <v-form @submit.prevent="handleSubmit(submitForm())">
          <v-card class="overflow-x-hidden overflow-y-auto" flat>
            <div v-for="(page, index) in form.pages" :key="index">
              <div v-show="currentPage === index" class="pages">
                <v-card class="overflow-x-hidden overflow-y-auto" flat>
                  <vue-form-generator :model="page" :options="formOptions" :schema="getPageSchema(index)" tag="div"/>
                  <v-row v-if="isLastPage" class="mb-16 mt-6" justify="center">
                    <v-btn :disabled="invalid || inspection.isDone()"
                           :loading="submitting" color="primary" type="submit">Done
                    </v-btn>
                  </v-row>
                </v-card>

                <v-footer class="pa-0" fixed>
                  <v-card class="footer__content-wrapper" elevation="3" height="100%" tile width="100%">
                    <v-row align="center" justify="center" no-gutters>
                      <v-col class="text-start" cols="4">
                        <v-btn v-if="currentPage" text x-small @click="prevPage">
                          <v-icon>mdi-chevron-left</v-icon>
                          Page {{ index }}
                        </v-btn>
                      </v-col>
                      <v-col cols="4">
                        <span class="page-number ma-auto">
                            Page {{ currentPage + 1 }} of {{ form.pages.length }}
                        </span>
                      </v-col>
                      <v-col class="text-end" cols="4">
                        <v-btn v-if="!isLastPage" right text x-small @click="nextPage">
                          Page {{ index + 2 }}
                          <v-icon v-if="$vuetify.rtl">mdi-chevron-left</v-icon>
                          <v-icon v-else>mdi-chevron-right</v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-card>
                </v-footer>
              </div>
            </div>
          </v-card>
        </v-form>
      </validation-observer>
    </v-card>
  </v-container>
</template>

<script>
import { ResponseType } from '@/modules/inspection/enums/response-type'
import { UPDATE_INSPECTION_FORM } from '@/modules/app/store/mutation-types'
import { QuestionType } from '@/modules/inspection/enums/question-type'
import { ValidationObserver } from 'vee-validate'

export default {
  name: 'KurccForm',
  components: {
    ValidationObserver
  },
  props: {
    inspection: {
      required: true
    },
    loading: {
      required: true,
      default: false
    }
  },
  data () {
    return {
      currentPage: 0,
      formOptions: {
        validateAfterLoad: false,
        validateAfterChanged: false,
        validateAsync: false
      },
      submitting: false
    }
  },
  computed: {
    form: {
      get () {
        return this.$store.state.inspection.form
      },
      set (v) {
        this.$store.commit(UPDATE_INSPECTION_FORM, v)
      }
    },
    lastPageIndex () {
      return this.form.pages ? this.form.pages.length - 1 : 0
    },
    isLastPage () {
      return this.currentPage === this.lastPageIndex
    }
  },
  watch: {
    lastPageIndex: {
      handler: function (v) {
        if (this.currentPage > v) {
          this.currentPage = this.lastPageIndex
        }
      }
    }
  },
  methods: {
    nextPage () {
      this.currentPage++
    },
    prevPage () {
      this.currentPage--
    },
    submitForm () {
      this.submitting = true
      this.$store.dispatch('submitInspection', { id: this.inspection.id }).then(value => {
        this.$emit('done')
      }).finally(() => {
        this.submitting = false
      })
    },
    getPageSchema (pageIndex) {
      return {
        groups: [
          {
            fields: this.generatePageItems(pageIndex)
          }
        ]
      }
    },
    generatePageItems (pageIndex) {
      const fields = []
      const questions = this.form.pages[pageIndex].questions

      this.generatePageSubItems(pageIndex, fields, questions)
      return fields
    },
    generatePageSubItems (pageIndex, fields, questions, level = 0, grandparentIndex = null, parentIndex = null) {
      this.$lodash.forEach(questions, (question, selfIndex) => {
        if (question.type === QuestionType.check) {
          this.generateCheck(pageIndex, grandparentIndex, parentIndex, selfIndex, fields, question)
        } else {
          this.generateSection(pageIndex, parentIndex, selfIndex, fields, question, level)
        }
      })
    },
    generateSection (pageIndex, parentIndex, selfIndex, fields, question, level) {
      level++
      const indexes = {
        pageIndex,
        parentIndex,
        questionIndex: selfIndex
      }

      fields.push(
        {
          type: 'KurccFormSection',
          item: question,
          indexes
        }
      )
      if (!this.isRepeatableSection(question)) {
        this.generatePageSubItems(pageIndex, fields, question.questions, level, level === 2 ? parentIndex : null, selfIndex)
      }
    },
    isRepeatableSection (question) {
      return question.options.repeatSection
    },
    generateCheck (pageIndex, grandparentIndex, parentIndex, selfIndex, fields, question) {
      fields.push(
        {
          type: 'label',
          label: this.generateCheckLabel(question)
        },
        this.prepareResponse(question, selfIndex, pageIndex, grandparentIndex, parentIndex)
      )
    },
    generateCheckLabel: function (question) {
      const arrow = this.$vuetify.rtl ? '←' : '→'
      return `<v-row no-gutters><div class="d-inline-block blue--text">${arrow}</div>
                    <div class="warning--text d-inline-block">${question.options.answerRequired ? '*' : ''}</div>
                    <div class="d-inline-block">${question.title ?? ''}</div></v-row>`
    },
    concatIsRequiredRule (question, rules = '') {
      if (this.$lodash.isEmpty(rules)) {
        return question.options.answerRequired ? 'required' : ''
      } else {
        return question.options.answerRequired ? 'required|' + rules : rules
      }
    },
    prepareResponse (question, questionIndex, pageIndex, grandparentIndex, parentIndex) {
      let obj = {}
      let model
      let weight = 1
      const indexes = {
        pageIndex,
        grandparentIndex,
        parentIndex,
        questionIndex
      }

      if (!this.$lodash.isNil(grandparentIndex)) {
        model = `questions[${grandparentIndex}].questions[${parentIndex}].questions[${questionIndex}].answer`
        const parentWeight = this.form.pages[pageIndex].questions[grandparentIndex].options.weight
        weight = this.form.pages[pageIndex].questions[grandparentIndex].questions[parentIndex].options.weight * parentWeight
      } else if (!this.$lodash.isNil(parentIndex)) {
        model = `questions[${parentIndex}].questions[${questionIndex}].answer`
        weight = this.form.pages[pageIndex].questions[parentIndex].options.weight
      } else {
        model = `questions[${questionIndex}].answer`
      }

      if (ResponseType['Multiple choice'] === question.options.response.type) {
        obj = {
          type: 'KurccFormMultiChoiceResponse',
          model,
          item: question,
          indexes,
          values: question.options.response.responseSet.items,
          rules: this.concatIsRequiredRule(question),
          weight
        }
      }

      if (ResponseType.Text === question.options.response.type) {
        obj = {
          type: 'KurccFormText',
          component: 'v-textarea',
          model,
          item: question,
          indexes,
          placeholder: 'Enter text',
          rows: 1,
          rules: this.concatIsRequiredRule(question)
        }
      }

      if (ResponseType.Number === question.options.response.type) {
        obj = {
          type: 'KurccFormNumber',
          model,
          item: question,
          indexes,
          placeholder: 'Enter number',
          rules: this.concatIsRequiredRule(question, 'numeric')
        }
      }

      if (ResponseType.Checkbox === question.options.response.type) {
        obj = {
          type: 'KurccFormCheckbox',
          model,
          item: question,
          indexes,
          rules: this.concatIsRequiredRule(question)
        }
      }

      if (ResponseType.Photo === question.options.response.type) {
        obj = {
          type: 'KurccFormImage',
          model,
          item: question,
          indexes,
          rules: this.concatIsRequiredRule(question)
        }
      }

      if (ResponseType['Date & Time'] === question.options.response.type) {
        obj = {
          type: 'KurccFormDateTimeResponse',
          model,
          item: question,
          indexes,
          rules: this.concatIsRequiredRule(question)
        }
      }

      if (ResponseType.Dropdown === question.options.response.type) {
        obj = {
          type: 'KurccFormDropdownResponse',
          model,
          item: question,
          indexes,
          values: question.options.response.responseSet.items,
          rules: this.concatIsRequiredRule(question)
        }
      }

      if (ResponseType.Annotation === question.options.response.type) {
        obj = {
          type: 'KurccFormSignatureResponse',
          model,
          showImageInput: true,
          item: question,
          indexes,
          rules: this.concatIsRequiredRule(question)
        }
      }

      if (ResponseType.Signature === question.options.response.type) {
        obj = {
          type: 'KurccFormSignatureResponse',
          model,
          item: question,
          indexes,
          rules: this.concatIsRequiredRule(question)
        }
      }

      if (ResponseType['No response required'] === question.options.response.type) {
        obj = {
          type: 'label',
          label: `questions[${questionIndex}].title`,
          model,
          visible: false
        }
      }

      return obj
    }
  }
}
</script>

<style lang="scss">
.v-footer {
  height: 56px;

  .v-btn {
    padding: 0;
  }

  .footer__content-wrapper {
    line-height: 56px;
  }
}

.page {
  position: relative;
}

ul.multiselect__content {
  padding: 0 !important;
}

.vue-form-generator {
  legend {
    margin: 5px auto;
    width: 100%;
    line-height: 25px;
  }
}

.form {
  legend {
    font-weight: bold;
    font-size: 14px;
  }
}

.pages {
  .field-label {
    margin: 0;

    label > span {
      font-size: 12px;
      margin: 0;

      &:before {
        margin-left: -12px;
        margin-right: 3px;
        content: '\F054';
        display: inline-block;
        font: normal normal normal 24px/1 "Material Design Icons";
        font-size: inherit;
        text-rendering: auto;
        line-height: inherit;
        -webkit-font-smoothing: antialiased;
      }
    }

    .field-wrap {
      display: none;
    }
  }

  .form-group.field-label {
    text-align: start;
  }

  .form-group {
    padding: 0 15px;
    margin-top: 10px;
  }

  .page-number {
    font-size: 10px;
  }
}
</style>
